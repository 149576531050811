import React from "react";
import cx from "classnames";

interface SectionHeadingProps {
  className?: string;
}

const SectionHeading: React.FunctionComponent<SectionHeadingProps> = ({
  children,
  className,
}) => {
  return (
    <h1 className={cx("font-bold text-2xl sm:text-3xl md:text-4xl text-center uppercase underline", className)}>
      {children}
    </h1>
  );
};
export default SectionHeading;
