import React from "react";
import ServiceCard from "../../components/ServiceCard";
import BrandImage from "../../images/branding.webp";
import ActiviationImage from "../../images/activation.webp";
import HometoHomeImage from "../../images/hometohome.webp";
import DistributorImage from "../../images/distributor.webp";
import HRServiceImage from "../../images/hr_service.webp";
import EventImage from "../../images/event.webp";
import SectionHeading from "../../components/SectionHeading";
import SectionContainer from "../../components/SectionContainer";
import { Link } from "gatsby";

const services = [
  {
    name: "Branding",
    imageUrl: BrandImage,
    url:'/#branding'
  },
  {
    name: "In shop",
    imageUrl: ActiviationImage,
    url:'/#inshop'
  },
  {
    name: "Home to Home",
    imageUrl: HometoHomeImage,
    url:'/#hometohome'
  },
  {
    name: "Events",
    imageUrl: EventImage,
    url:'/#events'
  },
  {
    name: "Distributor",
    imageUrl: DistributorImage,
    url:'/under_maintainance'
  },
  {
    name: "HR service",
    imageUrl: HRServiceImage,
    url:'/under_maintainance'
  },
  
];

const OurService = () => {
  return (
    <SectionContainer id="our_service" className="bg-white">
      <SectionHeading className="mb-5 text-blue-900">Our Services</SectionHeading>
      <div className="px-2">
        <div className="flex flex-wrap justify-center mb-5 -mx-2">
          {services.map(service => (
            <Link
              to={service.url}
              key={service.name}
              className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 mb-8 px-4"
            >
              <ServiceCard
                name={service.name}
                url={service.imageUrl}
                key={service.name}
              />
            </Link>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

export default OurService;
