import React from "react";
import SectionHeading from "../../components/SectionHeading";
import SectionContainer from "../../components/SectionContainer";
import loadable from "@loadable/component";

const LoadableLocation = loadable(() => import("./Location"));

const Contact = () => {
  return (
    <SectionContainer id="contact" className="bg-purple-100">
      <SectionHeading className="mb-5 text-blue-900">Contact</SectionHeading>
      <div className="flex flex-wrap -mx-2 order-2 md:order-1">
        <div className="w-full md:w-1/2 px-8 flex justify-center">
          <LoadableLocation />
        </div>
        <div className="w-full md:w-1/2 px-8 order-1 md:order-2">
          <div className="text-black font-semibold text-base md:text-xl text-center md:text-left p-4">
            <div className="leading-8 captialize">India One Brand Services</div>
            <div className="leading-8 captialize">
              26/B, Kamgar Nagar, Kamgar Road
            </div>
            <div className="leading-8 captialize">
              Kurla (E), Mumbai - 400024
            </div>
            <div className="leading-8 captialize">Maharashtra</div>
            <div className="leading-8 captialize">
              Contact - 09167755240, 022 25292960
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Contact;
