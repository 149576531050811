import React from "react";
import cx from "classnames";

interface SectionContainerProps {
  id?: string;
  className?: string;
  [props: string]: any;
}
const SectionContainer: React.FunctionComponent<SectionContainerProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cx(
        "p-4 md:p-8 flex flex-col items-center border-b",
        className
      )}
      {...props}
    >
      <div className="w-full lg:w-10/12">{children}</div>
    </div>
  );
};

export default SectionContainer;
