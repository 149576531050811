import React from "react";
import SectionHeading from "../../components/SectionHeading";
import SectionContainer from "../../components/SectionContainer";

const About = () => {
  return (
    <SectionContainer id="about" className="bg-yellow-400">
      <SectionHeading className="mb-5 text-blue-900">About us</SectionHeading>
      <div className="flex justify-center">
        <p className="w-3/4 font-semibold leading-8">
          For the last more than 16 years we have been working on events,
          branding, promotional activity and marketing business. We are known as
          leading and fastest growing BTL company having PAN India setup.We have
          successfully executed over 2000 events and BTL across 200 cities and
          5000 locations nationwide and to be known as a one of the most
          innovative and experienced agencies in the business.
        </p>
      </div>
    </SectionContainer>
  );
};

export default About;
