import React from "react";
import SectionContainer from "../../components/SectionContainer";
import SectionHeading from "../../components/SectionHeading";
import ImageGallery from "react-image-gallery";
import image1 from "../../images/events/image1.png";
import image2 from "../../images/events/image2.png";

const images = [
  {
    original: image1,
  },
  {
    original: image2,
  },
];

const Event = () => {
  return (
    <SectionContainer className="bg-orange-100" id="events">
      <SectionHeading className="mb-5 text-blue-900">Events</SectionHeading>
      <div className="p-4 md:p-6">
        <ImageGallery
          items={images}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          autoPlay
          showNav={false}
        />
      </div>
    </SectionContainer>
  );
};

export default Event;
