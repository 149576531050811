import React from "react";
import styled from "styled-components";
import cx from "classnames";

const ServiceCardWrapper = styled.div`
  .service-name {
    color: #1d3a64;
  }
`;
interface ServiceCardProps {
  name: string;
  url: string;
  className?: string;
}

const ServiceCard: React.FunctionComponent<ServiceCardProps> = ({
  name,
  url,
  className,
}) => {
  return (
    <ServiceCardWrapper
      className={cx(
        "w-full p-4 rounded-md cursor-pointer hover:bg-gray-100  transform transition duration-500 ease-in-out hover:scale-110 shadow",
        className
      )}
    >
      <div className="flex justify-center items-center h-32 md:h-40 ">
        <img
          src={url}
          className="w-full h-32 md:h-40 object-contain"
          alt="brand service"
        />
      </div>
      <div className="font-bold text-center text-base uppercase mt-4 service-name">{name}</div>
    </ServiceCardWrapper>
  );
};

export default ServiceCard;
