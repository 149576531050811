import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import Header from "../components/header";
import BrandServiceImage from "../images/brand_logo.png";
import OurService from "../containers/home/OurService";
import About from "../containers/home/About";
import Contact from "../containers/home/Contact";
import OurClient from "../containers/home/OurClient";
import Branding from "../containers/home/Branding";
import Event from "../containers/home/Event";
import InShop from "../containers/home/Inshop";
import HomeToHome from "../containers/home/HomeToHome";
import Loader from "../components/Loader";
import ScrollUp from "react-scrollup-lite";

const IndexPageWrapper = styled(Layout)`
  .brand-service {
    background-color: #efefef;
    min-height: 250px;
  }
  .service-heading {
    color: #0083ff;
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Loader>
      <IndexPageWrapper>
        <SEO title={data.site.siteMetadata.title} />
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="brand-service w-full flex justify-center p-4">
          <img
            src={BrandServiceImage}
            className="w-full lg:w-2/3 xl:w-4/6"
            alt="brand service"
          />
        </div>
        <OurService />
        <Branding />
        <InShop />
        <HomeToHome />
        <Event />
        <OurClient />

        <About />
        <Contact />
        <ScrollUp
          startPosition={0}
          showAtPosition={10}
          position="right"
          className="scroll-up"
          style={{
            right: "20px",
          }}
        >
          <button className="w-12 h-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="w-12 h-12 text-purple-700"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="16 12 12 8 8 12"></polyline>
              <line x1="12" y1="16" x2="12" y2="8"></line>
            </svg>
          </button>
        </ScrollUp>
      </IndexPageWrapper>
    </Loader>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default IndexPage;
