import React from "react";
import SectionHeading from "../../components/SectionHeading";
import SectionContainer from "../../components/SectionContainer";
import image1 from "../../images/clients/logo/01.jpg";
import image2 from "../../images/clients/logo/02.jpg";
import image3 from "../../images/clients/logo/03.jpg";
import image4 from "../../images/clients/logo/04.jpg";
import image5 from "../../images/clients/logo/05.jpg";
import image6 from "../../images/clients/logo/06.jpg";
import image7 from "../../images/clients/logo/07.jpg";
import image8 from "../../images/clients/logo/08.jpg";
import image9 from "../../images/clients/logo/09.jpg";
import image10 from "../../images/clients/logo/10.jpg";
import image11 from "../../images/clients/logo/11.jpg";
import image12 from "../../images/clients/logo/12.jpg";
import image13 from "../../images/clients/logo/13.jpg";
import image14 from "../../images/clients/logo/14.jpg";
import image15 from "../../images/clients/logo/15.jpg";
import image16 from "../../images/clients/logo/16.jpg";
import image17 from "../../images/clients/logo/17.jpg";
import image18 from "../../images/clients/logo/18.jpg";
import image19 from "../../images/clients/logo/19.jpg";
import image20 from "../../images/clients/logo/20.jpg";
import image21 from "../../images/clients/logo/21.jpg";
import image22 from "../../images/clients/logo/22.jpg";
import image23 from "../../images/clients/logo/23.jpg";
import image24 from "../../images/clients/logo/24.jpg";
import image25 from "../../images/clients/logo/25.jpg";
import image26 from "../../images/clients/logo/26.jpg";
import image27 from "../../images/clients/logo/27.jpg";
import image28 from "../../images/clients/logo/28.jpg";
import image29 from "../../images/clients/logo/29.jpg";
import image30 from "../../images/clients/logo/30.jpg";
import image31 from "../../images/clients/logo/31.jpg";
import image32 from "../../images/clients/logo/32.jpg";
import image33 from "../../images/clients/logo/33.jpg";
import image34 from "../../images/clients/logo/34.jpg";
import image35 from "../../images/clients/logo/35.jpg";
import image36 from "../../images/clients/logo/36.jpg";
import image37 from "../../images/clients/logo/37.jpg";
import cx from "classnames";
import styled from "styled-components";

const OurClientWrapper = styled(SectionContainer)`
  min-height: 250px;
`;

const clients = [
  { name: "", className: "w-5/12 sm:w-4/12 md:w-3/12 xl:w-2/12", url: image1 },
  { name: "", className: "w-3/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image2 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image3 },
  { name: "", className: "w-3/12 sm:w-3/12 md:w-3/12 xl:w-1/12", url: image4 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image5 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image6 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image7 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image8 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image9 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image10 },
  { name: "", className: "w-5/12 sm:w-3/12 md:w-2/12 xl:w-2/12", url: image11 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image12 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image13 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image14 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image15 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image16 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image17 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image18 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image19 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image20 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image21 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image22 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image23 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image24 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image25 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image26 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image27 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image28 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image29 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image30 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image31 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image32 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image33 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image34 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image35 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image36 },
  { name: "", className: "w-4/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image37 },
];

// let imports = "";
// let clients = "";

// for (let i = 0; i < 40; i++) {
//   let index = i + 1;
//   imports += `import image${index} from "../../images/clients/logo/${index}.jpg";`;
//   clients += `{ name: "", className: "w-3/12 sm:w-3/12 md:w-2/12 xl:w-1/12", url: image${index} },`;
// }

// console.log(imports);
// console.log(clients);

const OurClient = () => {
  return (
    <OurClientWrapper id="OurClient" className="bg-white">
      <SectionHeading className="mb-5 text-blue-900">
        Our Happy Clients
      </SectionHeading>

      <div className="flex flex-wrap -mx-2 justify-center">
        {clients.map(client => (
          <div
            className={cx(
              client.className,
              "px-4 mb-8 flex items-center cursor-pointer transform transition duration-500 ease-in-out hover:scale-110"
            )}
            key={client.url}
          >
            <img src={client.url} alt="client" className="w-full max-w-sm" />
          </div>
        ))}
      </div>
    </OurClientWrapper>
  );
};

export default OurClient;
